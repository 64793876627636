.inviteLabel{
  display: block;
  color: #000000;	
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;	
  font-size: 11px;	
  line-height: 18px;
}

.errorText {
  color: '#eb1616 !important';
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: '11px';
  line-height: '18px';
  margin-left: '12px';
}

.internalUserTextField{
  width: 100%;
  margin: 4px 0;
  height: 25px;
  border: none;
  border-bottom: 1px solid #a6a6a6;
  background: none;
  padding: 3px 3px 3px 5px;
  color: #000000;  
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;  
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
}
.internalUserInput{
  display: flex;
  input{
    &::placeholder{
      color:#D8D8D8;
    }
  }
}
.internalUserLogo{
  margin: 10px 14px 10px 0px;
}
.invalidInternalUserTextField{
  width: 100%;
  margin: 4px 0;
  height: 25px;
  border: 1px solid #eb1616;
  background: none;
  padding: 3px 3px 3px 5px;
  color: #000000;  
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;  
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
}

.internalUserTextField:focus {
  border: none;
  border-bottom: 1px solid #a6a6a6;
  background: #fff;
  outline: 0;
  padding: 3px 3px 3px 5px;
  color: #000000;  
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;  
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
}

.searchIcon {
  position: absolute;
  right: 0;
  bottom: 5px;
}

.usersList {
  position: absolute;
  top: 34px;
  z-index: 1000;
  background: #fff;
  width: 81%;
  padding: 0;
  max-height: 35vh;
  overflow: auto;
  border: 1px solid #ccc;
  margin: 2px 0px 2px 62px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
     width: 0px;
     background: transparent; /* Chrome/Safari/Webkit */
  }
}

.groupSuggestionList {
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 1000;
  background: #fff;
  width: 300px;
  padding: 0;
  max-height: 35vh;
  overflow: auto;
  border: 1px solid #ccc;
  margin: 0px 0px 0px 6px;
  scrollbar-width: 8px; /* Firefox */
  -ms-overflow-style: scroll;  /* IE 10+ */
  &::-webkit-scrollbar {
     width: 8px;
     background: #D3D3D3 /* Chrome/Safari/Webkit */
  }
}

.internalUsersInfo {
  padding-left: 0;
  margin-top: 0;
}

.internalUserName {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
  color: #000000;	
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;		
  font-size: 12px;	
  line-height: 18px;
}

.internalUserEmail {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
  color: #000000;	
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;		
  font-size: 10px;	
  line-height: 16px;
}
  
  .userDetails {
    list-style-type: none;
    padding: 5px;
  }
  
  .menuDrop {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .menuDrop li {
    border-bottom: 1px solid #E8E8E8;
    border-left: 1px solid #E8E8E8;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .usersDropdownMenu .MuiPopover-paper {
    max-width: 315px;
    width: 100%;
    background-color: white;
    height: auto;
    max-height: 35vh;
  }

  .menuDropDownSpan {
    margin: 12px 20px 6px 12px;
  }

  .migrattionStatus {
    margin-left: 4px;
  }