#nprogress .bar {
  background: #aaaef5 !important;
  height: 3px;
  z-index: 10000;
}

#nprogress .peg {
  box-shadow: 0 0 0.8rem #aaaef5, 0 0 5px #aaaef5 !important;
}

.h-2-black-bold {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  margin-left: 25px;
}

.administratorTabContainer {
  margin-bottom: 0;
}

.h-2-sub-title {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  margin-left: 25px;
}

.addButton {
  color: #FFFFFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  float: right;
  background-color: #0033AB;
  margin-right: 16px;
  height: 36px;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.filterActions {
  display: inline;
  float: right;
  margin-right: 26px;
}

.filterBtn {
  cursor: pointer;
  float: right;
  opacity: 1;
  border: 1px solid #0033ab;
  box-shadow: none;
  text-transform: none;
  font-size: 1rem;
  color: #0033ab;
  line-height: 18px;
  background-color: transparent;
  border-radius: 3px;
  min-height: 36px;
  max-height: 64px;
  padding: 7px 14px;
  margin: 0px 26px 5px 0px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;

  &:hover {
    border-radius: 3px;
    font-weight: 400;
    box-shadow: none;
    background-color: #0033ab;
    color: white
  }
}

.filterSelect {
  width: 100%;
  margin: 12px 20px 0px 12px;
}

.changeButton {
  color: #FFFFFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  float: right;
  background-color: #0033AB;
  margin-right: 3px;
  height: 34px;
  min-width: 84px;
  max-width: 150px;
  position: unset;
  border-radius: 3px;
  border: none;
  outline: none;
  top: 200px;
  cursor: pointer;
}

.disableButton {
  cursor: not-allowed;
}

.mainDiv {
  margin-top: 30px
}

.dashboardSuggestions {
  position: absolute;
  top: 41px;
  left: -5px;
  z-index: 1000;
  background: #fff;
  width: 330px;
  padding: 0;
  max-height: 35vh;
  overflow: auto;
  border: 1px solid #ccc;
  margin: 0px 0px 0px 6px;
  scrollbar-width: 8px;
  /* Firefox */
  -ms-overflow-style: scroll;

  /* IE 10+ */
  &::-webkit-scrollbar {
    width: 8px;
    background: #D3D3D3
      /* Chrome/Safari/Webkit */
  }
}

.groupHighlight {
  text-decoration: underline;
  font-weight: bold;
  background: #FFFF2E;
}

.suggestionHighlight {
  list-style-type: none;
  padding: 5px;

  &:hover {
    background: #DCDCDC;
  }
}

.addFilterButton {
  color: #FFFFFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  float: right;
  background-color: #0033AB;
  margin: 0px 3px 26px 0px;
  height: 34px;
  min-width: 84px;
  max-width: 150px;
  position: unset;
  border-radius: 3px;
  border: none;
  outline: none;
  top: 200px;
  cursor: pointer;
}

.parentGroupError {
  color: #eb1616 !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 18px;
  margin: 0px 0px 0px 12px;
}

.loadingTextStyle {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  margin: 4px 0px 0px 0px;
  text-align: center;
}

.dashboardSearchBox {
  height: 38px;
  background: #ffffff;
  width: 330px;
}

.groupSearch {
  float: right;
  margin: 0px 16px 5px 0px;
  position: relative;
}

.GroupsListHeader {
  height: 35px;
  list-style-type: none;
  border: 1px solid #F0F0F0;
  background-color: #E8E8E8;
  width: 100%;
  color: black;
  display: flex;
}

.HeaderIconColumn {
  padding: 8.5px;
  width: 35px;
  height: 35px;
  float: left;
  border-left: 1px solid #F0F0F0;
  font-size: 12px;
  text-align: center;
  line-height: 100%;

}

.HeaderIconColumn_edit {
  padding: 8.5px;
  width: 35px;
  height: 35px;
  float: left;
  border-left: 1px solid #F0F0F0;
  font-size: 12px;
  text-align: center;
  line-height: 100%;
}

.ClickableColumn {
  padding: 8.5px;
  width: 35px;
  height: 35px;
  float: left;
  border-left: 1px solid #F0F0F0;
  font-size: 12px;
  text-align: center;
  line-height: 100%;
}

.ClickableColumn:hover {
  padding: 6.5px 5px 5px 5px;
}

.GroupsTableHeaderIcon {
  height: 16px;
}

.GroupsTableInfoIcon {
  height: 20px;
  margin-top: -9px;
}

.EmailInfoIcon {
  height: 15px;
  margin-top: -26px;
  margin-bottom: -3px;
}

.GroupEdit {
  height: 16px;
  cursor: pointer;
}

.EditAppContainer {
  display: flex;
  height: 40%;
}

.EditAppIconContainer {
  display: flex;
  width: 20%;
  flex-direction: row-reverse;
  margin: 8px;
}

.AppEditIcon {
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px;
}

.GroupDisableIcon {
    padding: 4px;
    vertical-align: middle;
    height: 20px;
}

.FinalNodeIcon {
  float: left;
  height: 35px;
  padding: 8.5px;

}

.ArrowIcon {
  float: left;
  height: 35px;
  padding: 11px;
  cursor: pointer;
}

.ArrowRightIcon {
  height: 10px;
  margin: 8px;
  cursor: pointer;
}

.NotOpenedArrayContainer {
  width: 26px;
  height: 26px;
  background-color: #0033AB;
  border-radius: 5px;
  margin: 4.5px;
  float: left;
}

.ExpendedHeaderColumn {
  width: calc(100% - 140px);
  line-height: 35px;
  padding-left: 9px;
  float: left;
}

.ExpendedCell {
  width: calc(100% - 175px);
  line-height: 35px;
  padding-left: 9px;
  float: left;
  color: #0033AB;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ExpendedCell:hover,
.ArrowIcon:hover {
  cursor: pointer;
}

.Groupli {
  list-style-type: none;
  width: 100%;
}

.Group {
  height: 35px;
  overflow: visible;
  border-left: 1px solid #F0F0F0;
  border-right: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  background-color: #F7F7F7;
  width: 100%;
}

.Group:hover {
  background-color: #FFFFFF;
}

#responsive-dialog-title h6 {
  font-size: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  background-color: #F7F7F7;
}

#responsive-dialog-title {
  padding: 14px 24px 4px;
  background-color: #F7F7F7;
}

#dialogbutton {
  background: #fff;
  padding: 0px 0px 8px 22px;
  justify-content: flex-start;
}

#dialogbutton button:disabled,
#dialogbutton button[disabled] {
  opacity: 0.5;
}

#dialogbutton Button:hover {
  background-color: #0033AB;
  color: #fff
}

#dialogbutton Button {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  text-transform: lowercase;
  text-align: center;
  border: 1px solid #0033AB;
  cursor: pointer;
}

#dialogbutton span {
  text-transform: capitalize !important;
}

#addbg {
  background: #fff
}

.AppsListContainer {
  padding: 12px;
}

.MuiSnackbarContent-message {
  display: flex;
}

.MuiSnackbarContent-action {
  height: 90%;
}

.MuiSelect-select[aria-pressed='true']~svg {
  transform: rotate(180deg);
}

.MuiButton-label {
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow: hidden;
}

.gridAppData {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.menuIcon {
  float: right;
  color: white !important;
  padding: 2px !important;
  margin-right: 5px !important;
  background-color: #0033ab !important;
  border: 2px solid #0033ab !important;
  border-radius: 25% !important;
  min-width: 0px !important;
}

.childMenuImg {
  height: 10px;
}

.editChildApp {
  display: flex;
  flex-direction: row-reverse;
  margin: 5px;
}

.childMenuApp {
  margin-left: -12px;

  &:hover {
    text-decoration: underline;
  }
}

.childMenuApp>span {
  white-space: pre-wrap;
  word-break: break-word;
}

.appCategory {
  font-weight: bold;
  min-height: 35px !important;
  height: 35px;
  border: 1px solid #F0F0F0 !important;
  background-color: #E8E8E8 !important;
}

.show {
  font-size: 15px;
}

.MuiExpansionPanel-rounded {
  box-shadow: none;
}

.MuiExpansionPanelDetails-root {
  padding-bottom: 0px !important;
}

.MuiExpansionPanel-rounded:last-child {
  padding-bottom: 0px;
}

.MuiExpansionPanelSummary-expandIcon {
  border: 1px solid #0033ab !important;
  color: #0033ab !important;
  padding: 0px !important;
  border-radius: 5px !important;

  &:hover {
    border: 1px solid #0033ab !important;
  }
}

@media only screen and (max-width: 600px) {
  .HeaderIconColumn {
    display: none !important;
  }

  .ExpendedCell {
    width: calc(100% - 70px) !important;
  }

  .ExpendedHeaderColumn {
    width: calc(100% - 36px) !important;
  }
}

.editUserColumn {
  width: 30%;
  height: 80px;
  float: left;
  margin-top: -10px;
  padding-left: 10px;
}

@media only screen and (max-width: 600px) {
  .editUserColumn {
    width: 100%;
  }
}

.MuiBadge-anchorOriginTopRightRectangle{
  top: 14px !important;
  right: 6px !important;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  font-size: 10px !important;
}
