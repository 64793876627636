.App {
  width: 100%;
}

.table {
  display: flex;
  border: 1px solid #F0F0F0;
  flex-direction: column;
  margin: 20px 0;
}

.table-head,
.table-body {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F0F0F0;
}

.table-body-gray {
  background-color: #F9F9F9;
}

.table-body-white {
  background-color: #FFFFFF;
}

.table-head {
  display: none;
  background-color: #E8E8E8;
}

.xs-table-head {
  background-color: #E8E8E8;
  padding: 10px;
}

.auditTd {
  border-right: 1px solid #F0F0F0;
  display: flex;
  flex: 1;
  min-height: 40px;
  align-items: center;
}

.auditTd:nth-child(1){
  flex: 0.8;
}

.auditTd:nth-child(2),.auditTd:nth-child(4) {
  flex: 0.7;
}
.auditTd:nth-child(6) {
  flex: 0.2;
  position: relative;
  left: 15px;
  top:5px
}

/* .auditTd:last-child {
  flex: 0 0 25px;
  text-align: center;
  border: 0 none;
} */

.paginationDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
}

.table-body:last-child {
  border: 0 none;
}

.table-td .MuiFormControlLabel-root {
  margin: 0;
}

.removeitem {
  text-decoration: none;
  color: #EB1616;
}

.icon-left {
  display: flex;
  min-height: 20px;
  align-items: center;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #F0F0F0
}

.sort-icon {
  margin-left: auto;
  cursor: pointer;
}

.table-body .xs-hide,
.table-head .xs-hide {
  display: none;
}

.xs-show {
  display: block;
}

.noUsersInfo {
  height: 35px;
}

.noUsersIcon {
  position: relative;
  top: 2px;
}

.noUsersContent {
  display: inline-block;
  margin: 0;
  line-height: 16px;
  padding: 9.5px;
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.userName {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 22px;
}

.userEmail,
.userLastSigned {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 10px;
  line-height: 16px;
  word-break: break-all;
}

.userStatus {
  flex-direction: column;
  align-items: flex-start !important;
}

.userMsg {
  color: #EB1616;
}

.chipBgColor {
  background: #F16B16 !important;
  color: #FFFFFF !important;
}

.refreshBgColor {
  background: #CD3806 !important;
  color: #FFFFFF !important;
  border-radius: 50%;
}

.userInfo {
  padding: 5px
}

.invitationPending {
  color: #ff7602
}

.cursor-default {
  cursor: text !important
}

.userTimeout {
  max-width: 15% !important;
}

.userIcon {
  max-width: 5%;
  justify-content: center;
}

@media (min-width: 721px) {

  .table-body .xs-hide,
  .table-head .xs-hide {
    display: flex;
  }

  .table-head {
    display: flex;
  }

  .xs-show {
    display: none;
  }

  .table-head .table-td,
  .table-body .table-td {
    flex: 1;
    display: flex;
    border-right: 1px solid #F0F0F0;
    min-height: 40px;
    align-items: center;
    padding: 0 5px;
  }
}

.selectDiv{
  font-size: 11px !important;
}
